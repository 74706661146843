import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import actions from '../actions';

class CallBack extends React.Component {
  componentDidMount() {
    const { location, logIntoClient } = this.props;
    logIntoClient(location.hash);
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          height: '70vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default connect(
  (state, _) => ({
    location: state.router.location,
  }),
  (dispatch, _) => ({
    logIntoClient(hash) {
      dispatch(actions.app.auth.logIntoClient(hash));
    },
  }),
)(CallBack);
