import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import { Typography as Text } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import * as cx from 'classnames';
import actions from '../actions';
import * as selectors from '../selectors';
import { DetailsSection, ObjectsSection } from '../containers';
import { Fade } from '../animations';
import { AUSConfig, NZConfig, UKConfig } from '../configs';
import { haveRequiredFields } from '../utils';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '77vh',
  },
  stepContainer: {
    margin: '1vh 0 2vh 0',
    backgroundColor: 'rgb(245,245,245)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  stepWrapper: {
    margin: '0 12vw 0 12vw',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    width: '100%',
  },
  stepper: {
    backgroundColor: 'transparent',
    height: '100%',
  },
  stepRoot: {},
  iconWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 33,
    width: 33,
    borderRadius: 30,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  workFlowContainer: {
    display: 'flex',
    flex: 1,
    margin: '0 20vw 0 20vw',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      margin: '3vw 1vw 0 1vw',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  navigationBtnWrap: {
    alignSelf: 'flex-end',
    marginBottom: '2vh',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  papersContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50vw',
    minHeight: '60vh',
    [theme.breakpoints.down('sm')]: {
      minWidth: '68vw',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '90vw',
    },
  },
  infoColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    padding: '1vw',
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: theme.palette.primary.main,
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: theme.palette.primary.main,
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: theme.palette.grey[100],
    },
  },
  connectorLine: {
    transition: theme.transitions.create('border-color'),
  },
  activeStep: {
    paddingTop: 5,
    paddingBottom: 5,
    borderBottom: `3px solid ${theme.palette.primary.dark}`,
  },
  completedStep: {
    color: theme.palette.primary.main,
  },
  mobileStepperContainer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItem: 'center',
    },
  },
  mobileStep: {
    color: theme.palette.common.white,
    width: '100%',
    flexGrow: 1,
  },
  rootMobileProgress: {
    backgroundColor: theme.palette.primary.main,
  },
  mobileProgress: {
    backgroundColor: theme.palette.primary.secondary,
  },
  stepValidationError: {
    color: 'red',
  },
});

function Details({
  classes,
  loading,
  country,
  activeStep,
  setStep,
  nextStep,
  previousStep,
  validated,
  tempRequiredFields,
  accountDetails,
}) {

  const config = getConfig(country);
  const { steps } = getSteps(accountDetails,config);

  const atEnd = activeStep === steps.length - 1;

  const connector = (
    <StepConnector
      classes={{
        active: classes.connectorActive,
        completed: classes.connectorCompleted,
        disabled: classes.connectorDisabled,
        line: classes.connectorLine,
      }}
    />
  );

  return (
    <div className={classes.container}>
      <div className={classes.stepContainer}>
        <div className={classes.stepWrapper}>
          <Stepper
            activeStep={activeStep}
            connector={connector}
            classes={{
              root: classes.stepper,
            }}
          >
            {steps.map((step, key) => {
              const isActive = Number(key) === activeStep;
              const isCompleted = Number(key) < activeStep;
              return (
                <Step key={key} className={classes.stepRoot} onClick={() => setStep(key)}>
                  <StepLabel
                    active={Number(key) === activeStep}
                    completed={key < activeStep}
                    classes={{
                      root: cx(
                        {
                          [classes.activeStep]: isActive,
                          [classes.completedStep]: isCompleted,
                        },
                        'noselect',
                        'cursor-pointer',
                      ),
                    }}
                    icon={
                      <div className={classes.iconWrap}>
                        <Icon fontSize="small">{step.icon}</Icon>
                      </div>
                    }
                  >
                    <Text variant="body1" color="inherit">
                      {step.label}{' '}
                      {key === 0 && (!validated || !haveRequiredFields(tempRequiredFields)) && (
                        <b className={classes.stepValidationError}>*</b>
                      )}
                    </Text>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
      </div>
      <Fade timeout={150}>
        <div className={classes.workFlowContainer}>
          <div className={classes.navigationBtnWrap}>
            <Fab
              color="primary"
              aria-label="Back"
              onClick={previousStep}
              disabled={activeStep === 0}
            >
              <Icon>{'keyboard_arrow_left'}</Icon>
            </Fab>
          </div>

          <Paper elevation={0} className={classes.papersContainer}>
            <div className={classes.infoColumn}>
              {steps[activeStep].nodes.map((node, key) => {
                let Component = null;

                if (node.kind === 'details') {
                  Component = DetailsSection;
                }

                if (node.kind === 'objects') {
                  Component = ObjectsSection;
                }

                return (
                  Component && (
                    <Component
                      key={key + (node.objectIs || '')}
                      config={node}
                      validated={validated}
                    />
                  )
                );
              })}
            </div>
          </Paper>

          <div className={classes.navigationBtnWrap}>
            <Fab
              color="primary"
              aria-label="Next"
              onClick={() => nextStep(atEnd)}
              className={classes.stepControlBtn}
              disabled={loading || !validated || !haveRequiredFields(tempRequiredFields)}
            >
              <Icon>{atEnd ? 'send' : 'keyboard_arrow_right'}</Icon>
            </Fab>
          </div>
        </div>
      </Fade>
      <div className={classes.mobileStepperContainer}>
        <MobileStepper
          variant="progress"
          steps={steps.length}
          position="bottom"
          activeStep={activeStep}
          className={classes.mobileStep}
          classes={{
            root: classes.rootMobileProgress,
            progress: classes.mobileProgress,
          }}
          nextButton={
            <Button
              color="inherit"
              size="small"
              onClick={() => nextStep(atEnd)}
              disabled={loading || !validated || !haveRequiredFields(tempRequiredFields)}
            >
              Next
              <Icon>{'keyboard_arrow_right'}</Icon>
            </Button>
          }
          backButton={
            <Button color="inherit" size="small" onClick={previousStep} disabled={activeStep === 0}>
              <Icon>{'keyboard_arrow_left'}</Icon>
              Back
            </Button>
          }
        />
      </div>
    </div>
  );
}

const getSteps = (accountDetails, config) => {
  
  let temp = config;
  //hide Assets And Liabilities Section .. does lotsa of null checks 
  if (!!accountDetails 
    && !!accountDetails.extensionData 
    && !!accountDetails.extensionData.extensionData
    && !!accountDetails.extensionData.extensionData.StaticContent
    && accountDetails.extensionData.extensionData.StaticContent.includeAssets === false) {
    // filter out of the steps list    
    temp.steps = temp.steps.filter(e => e.id !== 'assets-liabilities');
  }
  return temp;
};

const getConfig = countryCode => {
  switch (countryCode) {
    case 'nzl':
      return NZConfig;
    case 'gbr':
      return UKConfig;
    case 'aus':
      return AUSConfig;
    default:
      return NZConfig;
  }
};



export default connect(
  (state, _) => ({
    activeStep: selectors.getActiveStep(state),
    loading: selectors.getAppLoading(state),
    country: selectors.getCountry(state),
    validated: selectors.getValidated(state),
    details: selectors.getDetails(state),
    tempRequiredFields: selectors.getTempRequiredFields(state),
    accountDetails: selectors.getAccountDetails(state),
  }),
  (dispatch, _) => ({
    setStep(key) {
      dispatch(actions.app.appState.setStep(key));
    },
    nextStep(isSubmitting) {
      dispatch(actions.app.data.updateClientData(!!isSubmitting));
    },
    previousStep() {
      dispatch(actions.app.appState.previousStep());
    },
  }),
)(withStyles(styles)(Details));
