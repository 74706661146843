import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';

const styles = _ => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

function BirthDatePicker({
  classes,
  value,
  onChange,
  onInputChange,
}) {
  moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';
  return (
    <div className={classes.container} noValidate>
      <DatePicker
        keyboard
        placeholder="DD/MM/YYYY"
        minDate={moment().subtract(110, 'year')}
        minDateMessage="Age can't be more than 110"
        maxDate={moment().subtract(16, 'year')}
        maxDateMessage="Age can't be less than 16"
        value={value && value !== '0001-01-01T00:00:00Z' ? moment(value) : null}
        onChange={(val) => {
          onChange(val.format());
          onInputChange(val.format());
        }}
        onInputChange={(e) => {

          const tempDate = moment(e.target.value, 'DD/MM/YYYY')
          const isKeyedIn = e.target.value.indexOf('_') === -1 

                if( isKeyedIn && tempDate.isValid() )
                {onInputChange(tempDate.format());}
                else if (e.target.value === '')
                {onInputChange(e.target.value);}

        }}
        format="DD/MM/YYYY"
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      />
    </div>
  );
}

export default withStyles(styles)(BirthDatePicker);
