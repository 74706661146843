import React, { Component } from 'react'
import posed from 'react-pose';
import { isIE } from '../helper/Constants'

const FadeDiv = posed.div({
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
});

export default class Fade extends Component {
  state = {
    animated: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ animated: true })
    }, this.props.timeout || 150)
  }

  render() {
    if (isIE) {
      return (
        <>{this.props.children}</>
      );
    }
    
    return (
      <>
        <FadeDiv pose={this.state.animated ? 'visible' : 'hidden'}>
          {this.props.children}
        </FadeDiv>
      </>
    )
  }
}