import React, { Component } from 'react';
import './StatusItem.css';

class StatusItem extends Component {
  render() {
    const setSuccess = () => {
      switch (this.props.success) {
        case true:
          return '\u2713';
        case false:
          return '\u2717';
        default:
          return ``;
      }
    };

    return (
      <div>
        <b className="statusitem-name">{this.props.name}</b> {setSuccess()} {this.props.value}
      </div>
    );
  }
}
export default StatusItem;