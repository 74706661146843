import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import { Typography as Text, Button } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as cx from 'classnames';

import { Fade } from '../animations';
import { RegisterForm, PasswordlessForm, NotSubscribed } from '../containers';
import * as selectors from '../selectors';
import actions from '../actions';

import { AUSConfig, NZConfig, UKConfig } from '../configs';

const styles = theme => ({
  banner: {
    textAlign: 'center',
    padding: '1.5vh 0',
    margin: '0',
    backgroundColor: theme.palette.primary.main,
  },
  bannerText: {
    color: theme.palette.primary.contrastText,
  },
  papersContainer: {
    display: 'flex',
    minHeight: '50vh',
    margin: '3vh 15vw 0 15vw',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      margin: '3vh 2vw 0 2vw',
    },
  },
  iframePaper: {
    margin: 0,
    minHeight: '100vh',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  infoColumn: {
    flex: 1,
    padding: '2vw',
  },
  canExpand: {
    borderLeft: '4px solid rgb(250,250,250)',
    [theme.breakpoints.down('sm')]: {
      borderTop: '4px solid rgb(250,250,250)',
    },
  },
  canHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  loadingContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(250,250,250)',
  },
  loadWrap: {
    width: '100%',
    height: '20%',
  },
});

function Register({
  iframe,
  classes,
  loading,
  loadingMessage,
  details,
  accountDetails,
  isLoggedIn,
  showPasswordless,
  showPasswordlessLogin,
  emailAlreadyExist,
  passwordlessEmail,
  changePasswordlessEmail,
  justSubmitted,
  detailsAreSubmitted,
  linkSentToEmail,
  sendLoginLink,
  resetSendLoginLink,
  openInNewWindow,
  country,
}) {
  let CONFIG_TO_USE = null;
  switch (country) {
    case 'nzl':
      CONFIG_TO_USE = NZConfig;
      break;
    case 'gbr':
      CONFIG_TO_USE = UKConfig;
      break;
    case 'aus':
      CONFIG_TO_USE = AUSConfig;
      break;
    default:
      CONFIG_TO_USE = NZConfig;
  }
  const { registration } = CONFIG_TO_USE;

  //Ensure Acquire profile is live
  let liveProfile = true
  if (accountDetails.status === 'L') {
    if (accountDetails.settings.AcquireStatus === 'R') {
      liveProfile = false
    }
  }

  let companyBlurb =
    accountDetails &&
    accountDetails.extensionData &&
    accountDetails.extensionData.extensionData &&
    accountDetails.extensionData.extensionData.StaticContent &&
    accountDetails.extensionData.extensionData.StaticContent.blurb;
  if (companyBlurb != null) {
    companyBlurb = companyBlurb.split('\n').map((i, key) => {
      return <p key={key}>{i}</p>;
    });
  }

  let companyWhatsNext =
    accountDetails &&
    accountDetails.extensionData &&
    accountDetails.extensionData.extensionData &&
    accountDetails.extensionData.extensionData.StaticContent &&
    accountDetails.extensionData.extensionData.StaticContent.whatsnext;
  if (companyWhatsNext != null) {
    companyWhatsNext = companyWhatsNext.split('\n').map((i, key) => {
      return <p key={key}>{i}</p>;
    });
  }

  let companyThinkAbout =
    accountDetails &&
    accountDetails.extensionData &&
    accountDetails.extensionData.extensionData &&
    accountDetails.extensionData.extensionData.StaticContent &&
    accountDetails.extensionData.extensionData.StaticContent.thinkabout;
  if (companyThinkAbout != null) {
    companyThinkAbout = companyThinkAbout.split('\n').map((i, key) => {
      return <p key={key}>{i}</p>;
    });
  }

  let companyUpdateInfo =
    accountDetails &&
    accountDetails.extensionData &&
    accountDetails.extensionData.extensionData &&
    accountDetails.extensionData.extensionData.StaticContent &&
    accountDetails.extensionData.extensionData.StaticContent.updateinfo;
  if (companyUpdateInfo != null) {
    companyUpdateInfo = companyUpdateInfo.split('\n').map((i, key) => {
      return <p key={key}>{i}</p>;
    });
  }

  const hasPropertyOverseas = details.extensionData.assetsAndLiabilities.haveOverseasProperty;
  const hasChildren = !!details.extensionData.children.length;
  const renderLoading = () => {
    return (
      <Paper elevation={3} className={cx(classes.papersContainer, classes.flexColumn)}>
        <div className={classes.loadWrap}>
          <LinearProgress />
        </div>
        {!!loadingMessage && (
          <div className={classes.loadingContainer}>
            <span>{loadingMessage} ...</span>
          </div>
        )}
      </Paper>
    );
  };

  const defBlurb = (
    <div>
      <Text variant="body1">
        'Congratulations on taking the first step to ensuring you, your family and your assets are
        protected in the future. To give advice we need to understand you and your individual
        circumstances.'
      </Text>
      <br />
      <Text variant="body1">
        By giving us some basic information now, we will be in a better position to give you great
        advice when you come to see us.
      </Text>
    </div>
  );

  const defWhatsNext = (
    <div>
      <Text variant="body1">
        'We’ll review the information you have provided.'
      </Text>
      <p>
        If you don’t already have an appointment, we will be in touch to organise the best time for
        an appointment. If you don’t hear from us in the next couple of days, please give us a call.
      </p>
      <Text variant="body1">What to bring to our appointment?</Text>
      <ul>
        <li>Identification – drivers licence, passport, birth certificate</li>
        <li>Details of your assets e.g. property, bank accounts, superannuation and investments</li>
        <li>Existing estate planning documents – Will & Power of Attorneys</li>
        {hasPropertyOverseas && <li>Your Trust deed</li>}
      </ul>
    </div>
  );

  const defThinkAbout = (
    <div>
      <Text variant="body1">
        There will be some important matters to discuss that you will need to make decisions on. In
        preparation can you think about:
      </Text>
      <ul>
        <li>
          Who you would like to be the executor of your Will. This is an important role as they are
          the person or organisation that will carry out the wishes in your will.
        </li>
        {hasChildren && (
          <li>Who would you like to be the guardian of any children under the age of 18.</li>
        )}
        <li>
          How would you like your estate to be distributed. The name and address of the
          beneficiaries who will receive your assets?
        </li>
        <li>Details of your funeral arrangements</li>
      </ul>
    </div>
  );

  return (
    <div>
      {!iframe && (
        <Fade timeout={450}>
          <div className={classes.banner}>
            <Text variant="h6" className={classes.bannerText}>
              LET'S PROTECT YOUR ESTATE
            </Text>
          </div>
        </Fade>
      )}
      {loading ? (
        renderLoading()
      ) : (
        <Paper
          elevation={3}
          className={cx(classes.papersContainer, { [classes.iframePaper]: iframe })}
        >
          {!iframe && !justSubmitted && (
            <div className={cx(classes.infoColumn, classes.canHide)}>
              <Text variant="h6">
                Welcome and thank you for choosing{' '}
                {(accountDetails &&
                  accountDetails.extensionData &&
                  accountDetails.extensionData.extensionData &&
                  accountDetails.extensionData.extensionData.StaticContent &&
                  accountDetails.extensionData.extensionData.StaticContent.companyname) ||
                  accountDetails.name}
              </Text>
              <span>{companyBlurb || defBlurb}</span>
            </div>
          )}
          <div className={cx(classes.infoColumn, classes.canExpand)}>
            
            {!liveProfile && (<NotSubscribed accountDetails={accountDetails}/>)}

            {liveProfile &&!isLoggedIn && !showPasswordlessLogin && (
              <RegisterForm
                liveProfile={liveProfile}
                iframe={iframe}
                showPasswordless={showPasswordless}
                registration={registration}
                accountDetails={accountDetails}
              />
            )}
            {liveProfile && !isLoggedIn && showPasswordlessLogin && (
              <PasswordlessForm
                emailAlreadyExist={emailAlreadyExist}
                passwordlessEmail={passwordlessEmail}
                changePasswordlessEmail={changePasswordlessEmail}
                showPasswordless={showPasswordless}
                linkSentToEmail={linkSentToEmail}
                sendLoginLink={sendLoginLink}
                resetSendLoginLink={resetSendLoginLink}
              />
            )}
            {liveProfile && isLoggedIn && (
              <div>
                {!justSubmitted && (
                  <Text variant="h6">Hi {details.name.first}, you are logged in.</Text>
                )}
                {justSubmitted && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text variant="h6" color={'textPrimary'}>
                      <Icon style={{ color: '#32CD32', marginRight: '5px' }}>check_circle</Icon>
                      Thank you, your information has been securely sent to us.
                    </Text>
                    <Text variant="h6" color="primary">
                      {(accountDetails &&
                        accountDetails.extensionData &&
                        accountDetails.extensionData.extensionData &&
                        accountDetails.extensionData.extensionData.StaticContent &&
                        accountDetails.extensionData.extensionData.StaticContent.whatsnexthd) ||
                        'What happens now?'}
                    </Text>

                    <span>
                      {companyWhatsNext || {
                        defWhatsNext,
                      }}
                    </span>

                    <Text variant="h6" color="primary">
                      {(accountDetails &&
                        accountDetails.extensionData &&
                        accountDetails.extensionData.extensionData &&
                        accountDetails.extensionData.extensionData.StaticContent &&
                        accountDetails.extensionData.extensionData.StaticContent.thinkabouthd) ||
                        'Things to think about'}
                    </Text>
                    <span>
                      {companyThinkAbout || {
                        defThinkAbout,
                      }}
                    </span>
                    <Text variant="h6" color="primary">
                      {(accountDetails &&
                        accountDetails.extensionData &&
                        accountDetails.extensionData.extensionData &&
                        accountDetails.extensionData.extensionData.StaticContent &&
                        accountDetails.extensionData.extensionData.StaticContent.updateinfohd) ||
                        'You can update your information at any time'}
                    </Text>
                    <p>
                      To do this just click <strong>EDIT DETAILS</strong> below and you’ll be able
                      to start updating.
                    </p>
                    <span>
                      {companyUpdateInfo ||
                        'If you already have an appointment with us we ask that all information is entered, no later than, the day before your appointment. This just gives us some time to review and prepare for your appointment.'}
                    </span>
                  </div>
                )}
                {detailsAreSubmitted ? (
                  <Text variant="body1">
                    Details submitted on {moment(detailsAreSubmitted).format('DD MMM YYYY')}. Click
                    to make modifications and resubmit
                  </Text>
                ) : (
                  <Text variant="body1">Click to continue where you left off</Text>
                )}
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    marginTop: '5vh',
                  }}
                >
                  {!iframe ? (
                    <Link to="/details" style={{ textDecoration: 'none' }}>
                      <Button variant="contained" color="primary">
                        EDIT DETAILS
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => openInNewWindow('/details')}
                    >
                      EDIT DETAILS
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </Paper>
      )}
    </div>
  );
}

export default connect(
  (state, _) => ({
    loading: selectors.getAppLoading(state),
    loadingMessage: selectors.getAppLoadingMessage(state),
    details: selectors.getDetails(state),
    accountDetails: selectors.getAccountDetails(state) || { address: {} },
    isLoggedIn: selectors.getIsLoggedIn(state),
    emailAlreadyExist: selectors.getEmailAlreadyExist(state),
    passwordlessEmail: selectors.getPasswordlessEmail(state),
    showPasswordlessLogin: selectors.getShowPasswordlessLogin(state),
    linkSentToEmail: selectors.getLinkSentToEmail(state),
    justSubmitted: selectors.getJustSubmitted(state),
    detailsAreSubmitted: selectors.getDetailsAreSubmitted(state),
    country: selectors.getCountry(state),
  }),
  (dispatch, _) => ({
    changePasswordlessEmail(email) {
      dispatch(actions.app.temporary.changeEmail(email));
    },
    showPasswordless(show) {
      dispatch(actions.app.temporary.showPasswordlessLogin(show));
    },
    sendLoginLink(email) {
      dispatch(actions.app.temporary.sendLoginLink(email));
    },
    resetSendLoginLink() {
      dispatch(actions.app.temporary.linkSentToEmail(false));
    },
    openInNewWindow(path) {
      dispatch(actions.app.temporary.openInNewWindow(path));
    },
  }),
)(withStyles(styles)(Register));
