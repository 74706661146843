import filter from 'ramda/src/filter';
import contains from 'ramda/src/contains';
import equals from 'ramda/src/equals';
import isEmpty from 'ramda/src/isEmpty';
import { or as hasTrue } from 'ramda';
import * as dotProp from 'dot-prop';

const isObject = obj => Object.prototype.toString.call(obj) === '[object Object]';

const isHidden = (data) => {
  const {
    details,
    hiddenTrigger,
  } = data;

  if (!hiddenTrigger) {
    return false;
  }

  const findMatches = (triggerType = []) => filter((condition) => {
    const {
      path: hiddenTriggerPath,
      pathValue: hiddenTriggerPathValue,
    } = condition;

    const dataValue = dotProp.get(details, hiddenTriggerPath);

    if (isObject(dataValue)) {
      return equals(dataValue, hiddenTriggerPathValue);
    }

    if (Array.isArray(dataValue)) {
      return Array.isArray(dataValue) && dataValue.length === hiddenTriggerPathValue.length;
    }

    return contains(
      dataValue,
      hiddenTriggerPathValue,
    );
  })(triggerType);

  return hasTrue(
    equals(findMatches(hiddenTrigger.and), hiddenTrigger.and),
    !isEmpty(findMatches(hiddenTrigger.or)),
  );
};

export default isHidden;
