import { assocPath, path as rPath } from 'ramda';
import { ReducerBuilder } from './reducer-builder';
import { dataStructure as defaultUserObj } from '../configs';

import actions from '../actions';

const defaultState = {
  details: defaultUserObj,
  newObjects: {},
  editingIndex: {},
  editingObject: {},
};

const reducer = new ReducerBuilder(defaultState)
  .handle(actions.app.data.registerClientSuccess, (state, action) => ({
    ...state,
    details: action.payload.data,
  }))
  .handle(actions.app.auth.logIntoClientSuccess, (state, action) => ({
    ...state,
    details: action.payload.data,
  }))
  .handle(actions.app.data.updateUserData, (state, action) => {
    let updatedDetails = { ...state.details };
    let pathArray = action.payload.path;

    if (typeof action.payload.path === 'string') {
      pathArray = [action.payload.path];
    }
    pathArray.map(path => {
      updatedDetails = assocPath(path.split('.'), action.payload.value, updatedDetails);
      return true;
    });

    return {
      ...state,
      details: updatedDetails,
    };
  })
  .handle(actions.app.data.updateNewObjData, (state, action) => {
    let updatedObj = { ...(state.newObjects[action.payload.objectIs] || {}) };
    let pathArray = action.payload.path;

    if (typeof action.payload.path === 'string') {
      pathArray = [action.payload.path];
    }
    pathArray.map(path => {
      updatedObj = assocPath(path.split('.'), action.payload.value, updatedObj);
      return true;
    });

    return {
      ...state,
      newObjects: {
        ...state.newObjects,
        [action.payload.objectIs]: {
          ...updatedObj,
        },
      },
    };
  })
  .handle(actions.app.data.clearNewObjData, (state, action) => ({
    ...state,
    newObjects: {
      ...state.newObjects,
      [action.payload.objectIs]: {},
    },
  }))
  .handle(actions.app.data.editObject, (state, action) => ({
    ...state,
    editingIndex: {
      ...state.editingIndex,
      [action.payload.objectIs]: action.payload.index,
    },
    editingObject: {
      ...state.editingObject,
      [action.payload.objectIs]: action.payload.currentData,
    },
  }))
  .handle(actions.app.data.updateEditObject, (state, action) => {
    let updatedObj = { ...(state.editingObject[action.payload.objectIs] || {}) };
    let pathArray = action.payload.path;

    if (typeof action.payload.path === 'string') {
      pathArray = [action.payload.path];
    }
    pathArray.map(path => {
      updatedObj = assocPath(path.split('.'), action.payload.value, updatedObj);
      return true;
    });

    return {
      ...state,
      editingObject: {
        ...state.editingObject,
        [action.payload.objectIs]: {
          ...updatedObj,
        },
      },
    };
  })
  .handle(actions.app.data.saveEditObject, (state, action) => {
    let updatedDetails = { ...state.details };
    let pathArray = action.payload.path;

    if (typeof action.payload.path === 'string') {
      pathArray = [action.payload.path];
    }
    pathArray.map(path => {
      let currentValue = rPath(path.split('.'), updatedDetails) || [];
      currentValue = [...currentValue];
      currentValue[state.editingIndex[action.payload.objectIs]] = {
        ...state.editingObject[action.payload.objectIs],
      };

      updatedDetails = assocPath(path.split('.'), currentValue, updatedDetails);
      return true;
    });

    return {
      ...state,
      details: updatedDetails,
    };
  })
  .handle(actions.app.data.cancelEditObject, (state, action) => ({
    ...state,
    editingIndex: {
      ...state.editingIndex,
      [action.payload.objectIs]: null,
    },
    editingObject: {
      ...state.editingObject,
      [action.payload.objectIs]: null,
    },
  }))
  .handle(actions.app.data.removeObject, (state, action) => {
    let updatedDetails = { ...state.details };
    let pathArray = action.payload.path;

    if (typeof action.payload.path === 'string') {
      pathArray = [action.payload.path];
    }
    pathArray.map(path => {
      let currentValue = rPath(path.split('.'), updatedDetails) || [];
      currentValue = [...currentValue];
      currentValue.splice(action.payload.index, 1);

      updatedDetails = assocPath(path.split('.'), currentValue, updatedDetails);
      return true;
    });

    return {
      ...state,
      details: updatedDetails,
    };
  })
  .handle(actions.app.auth.logoutSuccess, () => defaultState)
  .done();

export default reducer;
