import React from 'react'
import {
  path as rPath
} from 'ramda'
import { withStyles } from '@material-ui/core/styles';
import SingleTextInput from './SingleTextInput';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
})

function MultipleTextInput({
  classes,
  nodes,
  details,
  updateDetail,
  collectivePath
}) {

  return (
    <div className={classes.root}>
      {nodes.map((nodeConfig, index) => {
        const { path } = nodeConfig;
        const value = rPath(path.split('.'), details)
        const onChange = (newVal) => {
          const newCollectiveVal =  nodes.map(({path: componentPath}) => {
            if (path === componentPath) return newVal;
            return rPath(componentPath.split('.'),details) || ''
          }).join(' ')
          updateDetail(path, newVal);
          updateDetail(collectivePath, newCollectiveVal);
        }
        return (
          <SingleTextInput key={index} {...nodeConfig} onChange={onChange} value={value} />
        );
      })}
    </div>
  )
}

export default withStyles(styles)(MultipleTextInput);