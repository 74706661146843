export default {
  hashId: null,
  accountHashId: null,
  title: null,
  name: {
    first: null,
    middle: null,
    surname: null,
    fullName: null,
  },
  gender: 'unspecified',
  maritalStatus: 'unspecified',
  email: null,
  address: {
    street: null,
    suburb: null,
    city: null,
    region: null,
    postcode: null,
    country: null,
    coordinate: {
      latitude: null,
      longitude: null,
    },
  },
  dateOfBirth: '',
  mobile: null,
  telephone: null,
  preferredContactMethod: 'E',
  occupation: null, // "Software Developer"
  partnerName: {
    first: null,
    middle: null,
    surname: null,
    fullName: null,
  },
  partnerGender: 'Unspecified',
  partnerTitle: 'Unspecified',
  partnerDateOfBirth: null,
  createdOn: null,
  updatedOn: null,
  createdByHashId: null,
  updatedByHashId: null,
  deletedOn: null,
  data: null,
  settings: null,
  application: 'FactFind',
  extensionData: {
    contemplating: 'this is not applicable',
    partner: [],
    children: [
      /* sample:
        {
          "id": 1,
          "name": {
            "first": "Jay",
            "middle": "",
            "surname": "Bloggs",
            "fullName": "Jay Bloggs"
          },
          "gender": "male",
          "dependent": "yes",
          "parentage": "ours",
          "dateOfBirth": "2012"
        }
      */
    ],
    people: [],
    havePets: null,
    userComments: '',
    factFindMilestone: {
      family: null,
      contact: null,
      submitted: null,
      registration: null,
      profileStatus: null,
      assetsAndLiabilities: null,
    },
    assetsAndLiabilities: {
      assets: [
        /* sample:
          {
            "id": 1,
            "itemName": "Kiwi Saver",
            "ownership": "sole_ownership",
            "estimatedValue": 55000,
            "ownershipPercentage": null
          }
        */
      ],
      haveOverseasProperty: null,
      properties: [
        /* sample:
          {
            "id": 1,
            "address": {
              "city": "Auckland",
              "pxid": "2-.1.6.1R.8x4",
              "region": "Auckland Region",
              "street": "9 Aranui Road",
              "suburb": "Mount Wellington",
              "country": "New Zealand",
              "postcode": "1060",
              "coordinate": {
                "latitude": "174.837578",
                "longitude": "-36.914993"
              },
              "fullAddress": "9 Aranui Road, Mount Wellington, Auckland 1060"
            },
            "mortgage": 753251,
            "ownership": "joint_with_partner",
            "estimation": 1000000,
            "ownershipPercentage": null
          }
        */
      ],
      liabilities: [
        /* sample:
          {
            "id": 1,
            "itemName": "Personal Loan",
            "ownership": "sole_ownership",
            "estimatedValue": 5000,
            "ownershipPercentage": null
          }
        */
      ],
      haveBusinesses: null,
    },
    contactTimePreferrence: null,
    haveAnyOtherDependents: null,
  },
  externalReference: null,
  partnerHashId: null,
};
