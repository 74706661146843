import React, { useState } from 'react'
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Typography as Text } from '@material-ui/core';
import { BlockPicker } from 'react-color';

import actions from '../actions'
import * as selectors from '../selectors'

import { Fade } from '../animations';

const styles = theme => ({
  container: {
    display: 'flex',
    minHeight: '77vh',
    justifyContent: 'center',
  },
  workFlowContainer: {
    display: 'flex',
    margin: '0 8vw 0 8vw',
    [theme.breakpoints.down('sm')]: {
      margin: '0 2vw 0 2vw',
    },
    height: '100%',
  },
  papersContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5vh',
    minWidth: '70vw',
    minHeight: '60vh',
    padding: '2vw'
  },
  createThemeWrap: {
    display: 'flex',
    padding: '2vw',
    flexDirection: 'column',
    minHeight: '30vh',
    backgroundColor: 'rgb(240,240,240)'
  },
  colorPickWrap: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  themesListWrap: {
    minHeight: '20vh',
    paddingTop: '5vh'
  },
  list: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '3vh'
  },
  actionBtn: { margin: '1vw'},
  createBtn: {
    marginTop: '3vh'
  },
})

const defaultColors = {
  light: '#63a4ff',
  main: '#1976d2',
  dark: '#004ba0',
  contrastText: '#fff',
}

function Themes({
  classes,
  themes,
  createTheme,
  setTheme
}) {
  const [isExpand, setExpand] = useState(false);
  const [colors, setColor] = useState(defaultColors);
  const toggleExpand = () => setExpand(!isExpand)
  const handleColorChange = (kind) => (newColor) => setColor({
    ...colors,
    [kind]: newColor.hex
  })
  const { light, main, dark, contrastText } = colors; 

  return (
    <div className={classes.container}>
      <Fade timeout={150}>
        <div className={classes.workFlowContainer}>
          <Paper elevation={3} className={classes.papersContainer}>
              <div>
                <Text variant="h5" color="textPrimary">Themes Config</Text>
                {!isExpand &&
                  <Button variant="contained" color='primary' onClick={toggleExpand} className={classes.createBtn}>Create New Theme</Button>
                }
              </div>
            {isExpand &&
              <div className={classes.createThemeWrap}>
                <Text variant="h6" color="textPrimary">Create new theme</Text>
                <div className={classes.colorPickWrap}>
                  <div>
                    <Text variant="caption" color="textPrimary">Main</Text>
                    <BlockPicker color={main} onChangeComplete={handleColorChange('main')} />
                  </div>
                  <div>
                    <Text variant="caption" color="textPrimary">Light</Text>
                    <BlockPicker color={light} onChangeComplete={handleColorChange('light')} />
                  </div>
                  <div>
                    <Text variant="caption" color="textPrimary">Dark</Text>
                    <BlockPicker color={dark} onChangeComplete={handleColorChange('dark')} />
                  </div>
                  <div>
                    <Text variant="caption" color="textPrimary">Contrast Text</Text>
                    <BlockPicker color={contrastText} onChangeComplete={handleColorChange('contrastText')} />
                  </div>
                </div>
                <div className={classes.actionsContainer}>
                  <Button className={classes.actionBtn} variant="contained" color='primary' onClick={() => createTheme(colors)}>Create</Button>
                  <Button className={classes.actionBtn} variant="outlined" color='primary' onClick={toggleExpand}>Cancel</Button>
                </div>
              </div>
            }
            <div className={classes.themesListWrap}>
              <Text variant="h6" color="textPrimary">Themes list ({themes.length})</Text>
              <List dense className={classes.list}>
                {themes.map((theme, index) => {
                  const isDefault = index === 0;
                  return (
                    <ListItem key={index}>
                      {['light', 'main', 'dark', 'contrastText'].map(kind => (
                        <div key={kind} style={{
                          width: '10px',
                          height: '10px',
                          backgroundColor: theme.palette.primary[kind],
                          border: '1px solid black'
                        }}/>
                      ))}
                      <ListItemText
                        primary={isDefault ? 'Default theme' : `Theme ${index}`}
                        secondary={isDefault ? 'Provided' : 'Created'}
                      />
                     
                      <ListItemSecondaryAction>
                        <Button variant='outlined' onClick={() => setTheme(theme)}>
                          Apply
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
              </List>
            </div>
          </Paper>
        </div>
      </Fade>
    </div>
  )
}
export default connect(
  (state, _) => ({
    themes: selectors.getThemes(state),
  }),
  (dispatch, _) => ({
    createTheme(colors) {
      dispatch(actions.app.themes.createTheme(colors))
    },
    setTheme(theme) {
      dispatch(actions.app.themes.setTheme(theme))
    },
  })
)(withStyles(styles)(Themes))