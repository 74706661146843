export default {
  jurisdiction: 'New Zealand',
  steps: [
    {
      label: 'My Details',
      icon: 'face',
      nodes: [
        {
          kind: 'details',
          title: 'My Details',
          icon: 'face',
          nodes: [
            {
              path: 'title',
              title: 'My title',
              kind: 'pickOne',
              props: {
                options: ['Dr.', 'Mr', 'Prof.', 'Rev.', 'Sir', 'Miss', 'Ms', 'Mrs', 'Other'],
              },
            },
            {
              title: 'My name is',
              kind: 'multipleTextInput',
              props: {
                collectivePath: 'name.fullName',
                nodes: [
                  {
                    path: 'name.first',
                    placeholder: 'First Name',
                  },
                  {
                    path: 'name.middle',
                    placeholder: 'Middle Names',
                  },
                  {
                    path: 'name.surname',
                    placeholder: 'Surname',
                  },
                ],
              },
            },
            {
              path: 'gender',
              title: 'Gender',
              kind: 'pickOne',
              required: true,
              props: {
                options: ['male', 'female', 'unspecified'],
              },
            },
            {
              path: 'maritalStatus',
              title: 'Relationship',
              kind: 'pickOne',
              required: true,
              props: {
                options: [
                  'single',
                  'married',
                  'widow',
                  'stable union',
                  'in partnership',
                  'separated',
                  'unspecified',
                ],
              },
            },
            {
              path: 'extensionData.contemplating',
              title: 'Are you contemplating?',
              kind: 'pickOne',
              hiddenTrigger: {
                and: [
                  {
                    path: 'maritalStatus',
                    pathValue: ['married', 'stable union'],
                  },
                ],
              },
              props: {
                options: [
                  'a marriage',
                  'a civil union',
                  'either marriage or civil union',
                  'this is not applicable',
                ],
              },
            },
            {
              path: 'dateOfBirth',
              title: 'Date of birth',
              kind: 'birthDatePicker',
              required: true,
              caption: 'Please click the calendar icon or enter your Date of Birth',
            },
            {
              path: 'occupation',
              title: 'Occupation',
              kind: 'singleTextInput',
              props: {
                placeholder: 'E.g. Painter',
              },
            },
            {
              path: 'telephone',
              title: 'Phone',
              kind: 'singleTextInput',
              errorMessage: 'This field is required',
              requiredDependsOnPath: 'preferredContactMethod',
              props: {
                validations: ['validateNumber'],
                placeholder: 'E.g. 09 444 3334',
                type: 'number',
              },
            },
            {
              path: 'mobile',
              title: 'Mobile',
              kind: 'singleTextInput',
              errorMessage: 'This field is required',
              requiredDependsOnPath: 'preferredContactMethod',
              props: {
                validations: ['validateNumber'],
                placeholder: 'E.g. 021 555 6666',
                type: 'number',
              },
            },
            {
              path: 'email',
              title: 'Email',
              kind: 'singleTextInput',
              errorMessage: 'This field is required',
              requiredDependsOnPath: 'preferredContactMethod',
              props: {
                validations: ['validateEmail'],
                placeholder: 'E.g. john@provider.com',
              },
            },
            {
              path: 'preferredContactMethod',
              title: 'Preferred contact method',
              kind: 'pickOne',
              props: {
                options: ['phone', 'mobile', 'email'],
              },
            },
            {
              // TODO: implement address search
              path: 'extensionData.fullAddress',
              title: 'My primary residence',
              kind: 'addressInput',
              props: {
                nodes: [
                  {
                    path: 'suburb',
                    title: 'Suburb',
                    placeholder: 'E.g. Halfway Bush',
                  },
                  {
                    path: 'city',
                    title: 'City',
                    placeholder: 'E.g. Auckland',
                  },
                  {
                    path: 'region',
                    title: 'Region',
                    placeholder: 'E.g. Otago',
                  },
                  {
                    path: 'postcode',
                    title: 'Postcode',
                    placeholder: 'E.g. 1010',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      label: 'My Family',
      icon: 'people',
      nodes: [
        {
          path: 'extensionData.partner',
          kind: 'objects',
          isSingleObject: true,
          title: 'My Partner',
          icon: 'favorite',
          objectIs: 'partner',
          display: {
            capitalize: true,
            primaryInfoPath: 'title',
            secondaryInfoPath: 'fullName',
            secondaryInfoPrefix: ' ',
          },
          hiddenTrigger: {
            and: [
              {
                path: 'maritalStatus',
                pathValue: ['single', 'separated', 'widow', 'unspecified'],
              },
              {
                path: 'extensionData.contemplating',
                pathValue: ['this is not applicable'],
              },
            ],
          },
          nodes: [
            {
              path: 'title',
              title: 'Title',
              kind: 'pickOne',
              props: {
                options: ['Dr.', 'Mr', 'Prof.', 'Rev.', 'Sir', 'Miss', 'Ms', 'Mrs', 'Other'],
              },
            },
            {
              title: "Partner's name",
              kind: 'multipleTextInput',
              props: {
                collectivePath: 'fullName',
                nodes: [
                  {
                    path: 'name.first',
                    placeholder: 'First Name',
                  },
                  {
                    path: 'name.middle',
                    placeholder: 'Middle Names',
                  },
                  {
                    path: 'name.surname',
                    placeholder: 'Surname',
                  },
                ],
              },
            },
            {
              path: 'gender',
              title: 'Gender',
              kind: 'pickOne',
              props: {
                options: ['male', 'female', 'unspecified'],
              },
            },
            {
              path: 'dateOfBirth',
              title: 'Date of birth',
              kind: 'datePicker',
              caption: "Please click the calendar icon or enter your Partner's Date of Birth",
            },
          ],
        },
        {
          path: 'extensionData.children',
          kind: 'objects',
          title: 'My Children',
          icon: 'child_care',
          objectIs: 'child',
          display: {
            primaryInfoPath: 'fullName',
            secondaryInfoPath: 'birthYear',
            secondaryInfoPrefix: ', born in ',
          },
          groupedBy: 'parentage',
          nodes: [
            {
              title: "Child's name",
              kind: 'multipleTextInput',
              props: {
                collectivePath: 'fullName',
                nodes: [
                  {
                    path: 'name.first',
                    placeholder: 'First Name',
                  },
                  {
                    path: 'name.middle',
                    placeholder: 'Middle Names',
                  },
                  {
                    path: 'name.surname',
                    placeholder: 'Surname',
                  },
                ],
              },
            },
            {
              path: 'gender',
              title: 'Gender',
              kind: 'pickOne',
              props: {
                options: ['male', 'female', 'unspecified'],
              },
            },
            {
              path: 'birthYear',
              title: 'Year of birth',
              kind: 'singleTextInput',
              props: {
                validations: ['validateBirthYear'],
                placeholder: 'YYYY',
                type: 'number',
              },
            },
            {
              path: 'parentage',
              title: 'Parentage',
              kind: 'pickOne',
              hiddenTrigger: {
                and: [
                  {
                    path: 'extensionData.partner',
                    pathValue: [1],
                  },
                ],
              },
              props: {
                options: ['my child'],
              },
            },
            {
              path: 'parentage',
              title: 'Parentage',
              kind: 'pickOne',
              hiddenTrigger: {
                and: [
                  {
                    path: 'extensionData.partner',
                    pathValue: [],
                  },
                ],
              },
              props: {
                options: ['our child', 'my child', "partner's child"],
              },
            },
          ],
        },
        {
          kind: 'details',
          title: 'Do you care for the following',
          icon: 'pets',
          nodes: [
            {
              path: 'extensionData.haveAnyOtherDependents',
              title: 'Other Dependants',
              kind: 'switch',
              inline: true,
              props: {
                options: ['yes', 'no'],
              },
            },
            {
              path: 'extensionData.havePets',
              title: 'Pets',
              kind: 'switch',
              inline: true,
              props: {
                options: ['yes', 'no'],
              },
            },
          ],
        },
      ],
    },
    {
      label: 'My Assets & Liabilities',
      icon: 'attach_money',
      nodes: [
        {
          path: 'extensionData.assetsAndLiabilities.properties',
          kind: 'objects',
          title: 'My Property',
          icon: 'home',
          objectIs: 'property',
          display: {
            primaryInfoPath: 'fullAddress',
            secondaryInfoPath: 'estimatedValue',
          },
          groupedBy: 'ownership',
          showPieChart: true,
          chartConfig: {
            ownershipIndicator: {
              prop: 'ownership',
              value: 'you own',
              title: 'My Share',
            },
            positiveValue: {
              path: 'estimatedValue',
              title: 'Value',
            },
            negativeValue: {
              path: 'estimatedOwing',
              title: 'Owing',
            },
            yourShare: {
              path: 'yourShare',
              title: 'Value',
            },
            partnersShare: {
              path: 'partnersShare',
              title: 'Value',
            },
          },
          nodes: [
            {
              path: 'fullAddress',
              title: 'Property address',
              kind: 'addressInput',
              props: {
                nodes: [
                  {
                    path: 'suburb',
                    title: 'Suburb',
                    placeholder: 'E.g. Halfway Bush',
                  },
                  {
                    path: 'city',
                    title: 'City',
                    placeholder: 'E.g. Auckland',
                  },
                  {
                    path: 'region',
                    title: 'Region',
                    placeholder: 'E.g. Otago',
                  },
                  {
                    path: 'postcode',
                    title: 'Postcode',
                    placeholder: 'E.g. 1010',
                  },
                ],
              },
            },
            {
              path: 'ownership',
              title: 'Ownership',
              kind: 'pickOne',
              hiddenTrigger: {
                and: [
                  {
                    path: 'extensionData.partner',
                    pathValue: [],
                  },
                ],
              },
              props: {
                options: [
                  'you own',
                  'partner owns',
                  'jointly owned with partner',
                  'jointly owned',
                  'tenants in common',
                ],
              },
            },
            {
              path: 'ownership',
              title: 'Ownership',
              kind: 'pickOne',
              hiddenTrigger: {
                and: [
                  {
                    path: 'extensionData.partner',
                    pathValue: [1],
                  },
                ],
              },
              props: {
                options: ['you own'],
              },
            },
            {
              path: 'yourShare',
              title: 'Your percentage share',
              kind: 'singleTextInput',
              dynamic: true,
              dynamicTrigger: {
                path: 'ownership',
                pathValue: ['tenants in common', 'jointly owned'],
              },
              hiddenTrigger: {
                and: [
                  {
                    path: 'extensionData.partner',
                    pathValue: [],
                  },
                ],
              },
              props: {
                isPercentage: true,
                validations: ['validateNumber'],
                type: 'number',
              },
            },
            {
              path: 'partnersShare',
              title: "Partner's percentage share",
              kind: 'singleTextInput',
              dynamic: true,
              dynamicTrigger: {
                path: 'ownership',
                pathValue: ['tenants in common', 'jointly owned'],
              },
              hiddenTrigger: {
                and: [
                  {
                    path: 'extensionData.partner',
                    pathValue: [],
                  },
                ],
              },
              props: {
                isPercentage: true,
                validations: ['validateNumber'],
                type: 'number',
              },
            },
            {
              path: 'estimatedValue',
              title: 'Estimated value',
              kind: 'singleTextInput',
              props: {
                isMoney: true,
                validations: ['validateNumber'],
              },
            },
            {
              path: 'estimatedOwing',
              title: 'Estimated owing',
              kind: 'singleTextInput',
              props: {
                isMoney: true,
                validations: ['validateNumber'],
              },
            },
          ],
        },
        {
          path: 'extensionData.assetsAndLiabilities.assets',
          kind: 'objects',
          title: 'My Assets',
          icon: 'attach_money',
          objectIs: 'asset',
          display: {
            primaryInfoPath: 'itemName',
            secondaryInfoPath: 'estimatedValue',
          },
          groupedBy: 'ownership',
          showPieChart: true,
          chartConfig: {
            ownershipIndicator: {
              prop: 'ownership',
              value: 'you own',
              title: 'My Share',
            },
            positiveValue: {
              path: 'estimatedValue',
              title: 'Value',
            },
            yourShare: {
              path: 'yourShare',
              title: 'Value',
            },
            partnersShare: {
              path: 'partnersShare',
              title: 'Value',
            },
          },
          nodes: [
            {
              path: 'itemName',
              title: 'Please select your Asset type',
              kind: 'pickOne',
              props: {
                options: [
                  'motor vehicle',
                  'household content',
                  'kiwi saver',
                  'superannuation',
                  'jewellery',
                  'bank accounts',
                  'shares',
                  'life policies',
                  'other asset',
                ],
              },
            },
            {
              path: 'ownership',
              title: 'Ownership',
              kind: 'pickOne',
              hiddenTrigger: {
                and: [
                  {
                    path: 'extensionData.partner',
                    pathValue: [],
                  },
                ],
              },
              props: {
                options: ['you own', 'partner owns', 'jointly owned with partner'],
              },
            },
            {
              path: 'ownership',
              title: 'Ownership',
              kind: 'pickOne',
              hiddenTrigger: {
                and: [
                  {
                    path: 'extensionData.partner',
                    pathValue: [1],
                  },
                ],
              },
              props: {
                options: ['you own'],
              },
            },
            {
              path: 'yourShare',
              title: 'Your share',
              kind: 'singleTextInput',
              dynamic: true,
              dynamicTrigger: {
                path: 'ownership',
                pathValue: ['jointly owned with partner'],
              },
              hiddenTrigger: {
                and: [
                  {
                    path: 'extensionData.partner',
                    pathValue: [],
                  },
                ],
              },
              props: {
                isPercentage: true,
                validations: ['validateNumber'],
                type: 'number',
              },
            },
            {
              path: 'partnersShare',
              title: "Partner's share",
              kind: 'singleTextInput',
              dynamic: true,
              dynamicTrigger: {
                path: 'ownership',
                pathValue: ['jointly owned with partner'],
              },
              hiddenTrigger: {
                and: [
                  {
                    path: 'extensionData.partner',
                    pathValue: [],
                  },
                ],
              },
              props: {
                isPercentage: true,
                validations: ['validateNumber'],
                type: 'number',
              },
            },
            {
              path: 'estimatedValue',
              title: 'Estimated value',
              kind: 'singleTextInput',
              caption: 'This amount should exclude any money owing',
              props: {
                isMoney: true,
                validations: ['validateNumber'],
                type: 'number',
              },
            },
          ],
        },
        {
          path: 'extensionData.assetsAndLiabilities.liabilities',
          kind: 'objects',
          title: 'My Liabilities',
          icon: 'credit_card',
          objectIs: 'liability',
          display: {
            primaryInfoPath: 'itemName',
            secondaryInfoPath: 'estimatedValue',
          },
          groupedBy: 'ownership',
          showPieChart: true,
          chartConfig: {
            ownershipIndicator: {
              prop: 'ownership',
              value: 'you own',
              title: 'My Share',
            },
            positiveValue: {
              path: 'estimatedValue',
              title: 'Value',
            },
            yourShare: {
              path: 'yourShare',
              title: 'Value',
            },
            partnersShare: {
              path: 'partnersShare',
              title: 'Value',
            },
          },
          nodes: [
            {
              path: 'itemName',
              title: 'Please select your Liability type',
              kind: 'pickOne',
              props: {
                options: [
                  'motor vehicle',
                  'personal loan',
                  'credit cards',
                  'phone',
                  'other loans',
                  'overdrafts',
                  'second mortgages',
                  'other liability',
                ],
              },
            },
            {
              path: 'ownership',
              title: 'Ownership',
              kind: 'pickOne',
              hiddenTrigger: {
                and: [
                  {
                    path: 'extensionData.partner',
                    pathValue: [],
                  },
                ],
              },
              props: {
                options: ['you owe', 'partner owes', 'jointly owing with partner'],
              },
            },
            {
              path: 'ownership',
              title: 'Ownership',
              kind: 'pickOne',
              hiddenTrigger: {
                and: [
                  {
                    path: 'extensionData.partner',
                    pathValue: [1],
                  },
                ],
              },
              props: {
                options: ['you owe'],
              },
            },
            {
              path: 'yourShare',
              title: 'Your share',
              kind: 'singleTextInput',
              dynamic: true,
              dynamicTrigger: {
                path: 'ownership',
                pathValue: ['jointly owing with partner'],
              },
              hiddenTrigger: {
                and: [
                  {
                    path: 'extensionData.partner',
                    pathValue: [],
                  },
                ],
              },
              props: {
                isPercentage: true,
                validations: ['validateNumber'],
                type: 'number',
              },
            },
            {
              path: 'partnersShare',
              title: "Partner's share",
              kind: 'singleTextInput',
              dynamic: true,
              dynamicTrigger: {
                path: 'ownership',
                pathValue: ['jointly owing with partner'],
              },
              hiddenTrigger: {
                and: [
                  {
                    path: 'extensionData.partner',
                    pathValue: [],
                  },
                ],
              },
              props: {
                isPercentage: true,
                validations: ['validateNumber'],
                type: 'number',
              },
            },
            {
              path: 'estimatedValue',
              title: 'Estimated value',
              kind: 'singleTextInput',
              props: {
                isMoney: true,
                validations: ['validateNumber'],
                type: 'number',
              },
            },
          ],
        },
        {
          kind: 'details',
          title: 'Do you own or have shares in any of the following',
          icon: 'business_center',
          nodes: [
            {
              path: 'extensionData.assetsAndLiabilities.haveBusinesses',
              title: 'Businesses',
              kind: 'switch',
              inline: true,
              props: {
                options: ['yes', 'no'],
              },
            },
            {
              path: 'extensionData.assetsAndLiabilities.haveOverseasProperty',
              title: 'Overseas Property',
              kind: 'switch',
              inline: true,
              props: {
                options: ['yes', 'no'],
              },
            },
          ],
        },
      ],
    },
    {
      label: 'Send Information',
      icon: 'mail',
      nodes: [
        {
          title: 'Send Information',
          kind: 'details',
          icon: 'mail',
          nodes: [
            {
              path: 'extensionData.userComments',
              kind: 'sendInfo',
              props: {
                disclaimers: [
                  'Thank you for providing company_name with your information.',
                  'The information you have provided will help ensure you receive the most value from our meeting.',
                ],
                placeholder: "Is there anything else you'd like to tell us before we meet?",
              },
            },
          ],
        },
      ],
    },
  ],
  registration: {
    termsAndConditionsText:
      "By clicking the 'Get Started' button, I am happy to send my information to company_name as outlined in the Terms and Conditions",
    termsAndConditionsLink: 'Terms and Conditions',
  },
};
