export class ReducerBuilder {
  actionMap = {};
  defaultState = {};

  constructor(defaultState) {
    this.defaultState = defaultState || {};
  }

  handle(
    creator,
    reducer
  ) {
    const type = creator.toString();
    if (this.actionMap[type]) {
      throw new Error(`Already handling an action with type ${type}`);
    }
    this.actionMap[type] = reducer;
    return this;
  }

  handleMultiple(
    actions,
    reducer
  ) {
    for (const action of actions) {
      this.handle(action, reducer);
    }

    return this;
  }

  handleString(
    actionName,
    reducer
  ) {
    if (this.actionMap[actionName]) {
      throw new Error(`Already handling an action with type ${actionName}`);
    }
    this.actionMap[actionName] = reducer;
    return this;
  }

  done() {
    const mapClone = { ...this.actionMap };
    return (state = this.defaultState, action) => {
      const handler = mapClone[action.type];
      return handler ? handler(state, action) : state;
    };
  }
}
