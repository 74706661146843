import { createActions } from 'redux-actions';

export default createActions({
  APP: {
    AUTH: {
      LOG_IN: undefined,
      LOG_INTO_CLIENT: hash => ({ hash }),
      LOG_INTO_CLIENT_SUCCESS: data => ({ data }),
      LOG_INTO_CLIENT_FAILURE: error => ({ error }),
      REGISTER: undefined,
     // SET_AUTH_TOKEN: authToken => ({ authToken }),
      LOGOUT: undefined,
      LOGOUT_SUCCESS: undefined,
      LOGOUT_FAILURE: error => ({ error }),
    },
    APP_STATE: {
      SET_ACCOUNT_DETAILS: accountDetails => ({ accountDetails }),
      SET_STEP: key => ({ key }),
      NEXT_STEP: undefined,
      PREVIOUS_STEP: undefined,
      START_LOADING: loadingMessage => ({ loadingMessage }),
      STOP_LOADING: undefined,
      SET_SHOW_NOTIFICATION: show => ({ show }),
      SET_COUNTRY: country => ({ country }),
      SET_ACCOUNT_ID: accountId => ({ accountId }),
      UPDATE_VALIDATE: (path, value, details) => ({ path, value, details }),
      UPDATE_REQUIRED: (path, value, required) => ({ path, value, required }),
      UPDATE_TEMP_REQUIRED_FIELDS: (path, value, required) => ({ path, value, required }),
      SET_TERMS_AND_CONDITIONS_TICKED: isChecked => ({ isChecked }),
    },
    DATA: {
      UPDATE_USER_DATA: (path, value) => ({ path, value }),
      UPDATE_NEW_OBJ_DATA: (path, value, objectIs) => ({ path, value, objectIs }),
      CLEAR_NEW_OBJ_DATA: objectIs => ({ objectIs }),
      EDIT_OBJECT: (index, currentData, objectIs) => ({ index, currentData, objectIs }),
      UPDATE_EDIT_OBJECT: (path, value, objectIs) => ({ path, value, objectIs }),
      CANCEL_EDIT_OBJECT: objectIs => ({ objectIs }),
      SAVE_EDIT_OBJECT: (path, objectIs) => ({ path, objectIs }),
      REMOVE_OBJECT: (path, index) => ({ path, index }),
      /* ASYNC */
      GET_CLIENT_DATA: undefined,
      REGISTER_CLIENT: undefined,
      REGISTER_CLIENT_SUCCESS: data => ({ data }),
      REGISTER_CLIENT_FAILURE: error => ({ error }),
      UPDATE_CLIENT_DATA: isSubmitting => ({ isSubmitting }),
      UPDATE_CLIENT_DATA_SUCCESS: respond => ({ respond }),
      UPDATE_CLIENT_DATA_FAILURE: error => ({ error }),
      SUBMIT_CLIENT_DATA: undefined,
    },
    THEMES: {
      CREATE_THEME: colors => ({ colors }),
      CREATE_THEME_SUCCESS: theme => ({ theme }),
      CREATE_THEME_FAILURE: error => ({ error }),
      SET_THEME: theme => ({ theme }),
    },
    CONFIG: {
      SAVE_CONFIG: config => ({ config }),
      SAVE_CONFIG_SUCCESS: undefined,
      SAVE_CONFIG_FAILURE: error => ({ error }),

      LOAD_CONFIG: config => ({ config }),
      LOAD_CONFIG_SUCCESS: undefined,
      LOAD_CONFIG_FAILURE: error => ({ error }),

      UPDATE_CONFIG: config => ({ config }),
      UPDATE_CONFIG_SUCCESS: undefined,
      UPDATE_CONFIG_FAILURE: error => ({ error }),
    },
    TEMPORARY: {
      REHYDRATED: undefined,
      JUST_SUBMITTED: undefined,
      CHANGE_EMAIL: email => ({ email }),
      SET_EMAIL_ALREADY_EXIST: undefined,
      SHOW_PASSWORDLESS_LOGIN: show => ({ show }),
      LINK_SENT_TO_EMAIL: sent => ({ sent }),
      SEND_LOGIN_LINK: email => ({ email }),
      SEND_LOGIN_LINK_FAILURE: error => ({ error }),
      OPEN_IN_NEW_WINDOW: path => ({ path }),
    },
  },
});
