import React from 'react';
import './Status.css';
import configData from '../../api/config';
import StatusItem from './components/StatusItem';
import ApiStatusItem from './components/ApiStatusItem';
import { connect } from 'react-redux';
import * as selectors from '../../selectors';

function Status({ accountId,clientHashId }) {
  const config = configData[process.env.REACT_APP_ENVIRONMENT]['gbr'];

  return (
    <div className="status-container">
      <h1>Status</h1>
      <h2>
        <StatusItem name="Check" value="Status" />
      </h2>
      <StatusItem name="Environment" value={process.env.REACT_APP_ENVIRONMENT} />
      <StatusItem name="Acquire Version" value={process.env.REACT_APP_VERSION} />
      <StatusItem name="Build Version - PipeLineID" value={process.env.REACT_APP_PIPELINEID} />
      <ApiStatusItem url={[config.apiUrls.account, '?hashid=', accountId].join('')} />
      <ApiStatusItem url={[config.apiUrls.client, '?hashid=', clientHashId].join('')} />

     </div>
  );
}

export default connect(
  (state, _) => ({
    accountId: selectors.getAccountId(state),
    clientHashId : selectors.getDetails(state).hashId
  }),
  {},
)(Status);
