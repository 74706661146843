import config from '../api/config';
require('dotenv').config();

export const getActiveStep = state => state.appState.activeStep;
export const getAppLoading = state => state.appState.isLoading;
export const getAppLoadingMessage = state => state.appState.loadingMessage;
export const getAccountId = state => state.appState.accountId;
export const getCountry = state => state.appState.country;
export const getAccountDetails = state => state.appState.accountDetails;
export const getShowNotification = state => state.appState.showNotification;
export const getValidated = state => state.appState.validated;
export const getTempRequiredFields = state => state.appState.tempRequiredFields;
export const getTermsAndConditionsTicked = state => state.appState.termsAndConditionsTicked;

/*
export const getRequired = state => {
  const arr = [];

  const { gender, maritalStatus, dateOfBirth } = state.details.details;

  if (gender === '') {
    arr.push('gender');
  }

  if (maritalStatus === '') {
    arr.push('maritalStatus');
  }
  console.log("dob",dateOfBirth);
  if (dateOfBirth === '' || dateOfBirth === '0001-01-01T00:00:00Z') {
    arr.push('dateOfBirth');
  }

  return arr;
};
*/

export const getCurrency = state => config.currencySymbols[state.appState.country];
export const getAddress = state =>
  config[process.env.REACT_APP_ENVIRONMENT][state.appState.country].address;
export const getCallbackAddress = state =>
  config[process.env.REACT_APP_ENVIRONMENT][state.appState.country].callback;
export const getApiUrls = state =>
  config[process.env.REACT_APP_ENVIRONMENT][state.appState.country].apiUrls;
export const getApiConfig = state => {
  return {
    ...config[process.env.REACT_APP_ENVIRONMENT][state.appState.country],
    oAuthConfig: {
      ...config[process.env.REACT_APP_ENVIRONMENT][state.appState.country].oAuthConfig,
      ...config.sharedOAuthConfig,
    },
  };
};
