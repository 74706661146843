import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import createRootReducer from './reducers';
import mySaga from './sagas';

export const history = createBrowserHistory();

export default function create() {
  const sagaMiddleware = createSagaMiddleware({
    onError(error) {
      // tslint:disable-next-line: no-console
      console.log('ERROR', error);
    },
  });

  const store = createStore(
    createRootReducer(history),
    composeWithDevTools(
      applyMiddleware(thunk, sagaMiddleware),
      applyMiddleware(routerMiddleware(history)),
    ),
  );

  const persistor = persistStore(store);
  // persistor.purge();

  sagaMiddleware.run(mySaga, persistor);

  return store;
}
