import React from 'react';
import './App.css';
import { connect } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { Root } from './pages';
import * as selectors from './selectors';

function App({
  // accountTheme,
  accountDetails,
}) {
   const primaryColor =
    (accountDetails &&
      accountDetails.extensionData &&
      accountDetails.extensionData.extensionData &&
      accountDetails.extensionData.extensionData.Themes &&
      accountDetails.extensionData.extensionData.Themes.primary) ||
    '#6ea7d2';
 
  const secondaryColor =
    (accountDetails &&
      accountDetails.extensionData &&
      accountDetails.extensionData.extensionData &&
      accountDetails.extensionData.extensionData.Themes &&
      accountDetails.extensionData.extensionData.Themes.secondary) ||
    '#3F7CB6';

  const newLogo =
    (accountDetails &&
      accountDetails.extensionData &&
      accountDetails.extensionData.extensionData &&
      accountDetails.extensionData.extensionData.Logos &&
      accountDetails.extensionData.extensionData.Logos.mainLogo) ||
    '';

  const theme = createMuiTheme({
    typography: {
      useNextVariants: true,
    },
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
    },
  });

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <MuiThemeProvider theme={theme}>
        <Root logos={newLogo} />
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default connect((state, _) => ({
  accountDetails: selectors.getAccountDetails(state),
}))(App);
