import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { InputAdornment, TextField } from '@material-ui/core';
import * as Validations from '../helper/Validations';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

function SingleTextInput({
  classes,
  placeholder,
  type,
  isMoney,
  isPercentage,
  currencySymbol,
  validations = [],
  title,
  value,
  onChange,
  preventError,
}) {
  const onModify = e => onChange(e.target.value);

  let hasError = false;
  if (validations.length) {
    validations.map(method => {
      if (!Validations[method](value)) {
        hasError = true;
      }

      return hasError;
    });
  }

  return (
    <div className={classes.container}>
      <TextField
        placeholder={placeholder || title}
        className={classes.textField}
        onChange={onModify}
        value={value || ''}
        error={(validations && hasError) || preventError}
        type={type}
        InputProps={{
          startAdornment:
            (isMoney && <InputAdornment position="start">{currencySymbol}</InputAdornment>) ||
            (isPercentage && <InputAdornment position="start">%</InputAdornment>),
        }}
      />
    </div>
  );
}

export default withStyles(styles)(SingleTextInput);
