import React, { useState } from 'react';
import StatusItem from './StatusItem';
import axios from 'axios';

function ApiStatusItem(props) {
  const [data, setData] = useState('none');

  axios
    .get(props.url)
    .then((e) => setData( e.status === 200? 'Success' :`Http code:${e.status}, message: ${e.statusText}`)
    ).catch((e) => setData(e.toString()));

  return <StatusItem name={props.url} value={data} success={data === 'Success'} />;
}

export default ApiStatusItem;
