import React from 'react';
import { path as rPath, clone } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography as Text } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import * as cx from 'classnames';

import { Chart, QuestionWrap } from '../components';
import { isHidden } from '../utils';

const accounting = require("accounting");
const pluralize = require("pluralize");

const styles = theme => ({
  flexCentered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  flexColumnCentered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  addBtn: {
    minWidth: '30%',
    justifyContent: 'space-between',
  },
  objectEditContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'rgb(254,254,254)',
    minHeight: '20vh',
    margin: '2vh',
    padding: '1.5vh'
  },
  objectListContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px'
  },
  mainInputForm: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  formActionsWrap: {

  },
  actionBtn: {
    marginRight: '1vw'
  },
  mainGraphWrap: {
    width: '100%',
    paddingTop: 5,
    paddingBottom: 5,
    marginBottom: 4,
    backgroundColor: '#e8f4f8'
  },
  list: {
    width: '100%'
  },
  listItem: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'rgb(251,251,251)',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '5px',
    marginBottom: '5px',
    padding: '5px'
  },
  groupHeader: {
    width: '100%',
    borderBottom: `2px solid ${theme.palette.primary.light}`,
    color: 'theme.palette.primary.main',
    fontSize: '65%',
    textAlign: 'left'
  }
})

function EditingObject({
  classes,
  config,
  saveEditObject,
  cancelEditObject,
  updateEditObjWithObjIs,
  obj,
  editingObject,
  removeObject,
  currencySymbol,
  updateValidate,
  details,
}) {
  return (
    <Paper elevation={2} className={classes.objectEditContainer}>
      <Text variant="h6">Editing {config.objectIs}</Text>
      <div className={classes.mainInputForm}>
        {config.nodes && config.nodes.map((nodeConfig, index) => {
          return !isHidden({
            details,
            hiddenTrigger: nodeConfig.hiddenTrigger,
          }) && (
            <QuestionWrap
              key={obj.objectIndex + nodeConfig.path}
              {...nodeConfig}
              currencySymbol={currencySymbol}
              updateDetail={updateEditObjWithObjIs}
              updateValidate={updateValidate}
              details={editingObject}
            />
          );
        })}
      </div>
      <div className={classes.formActionsWrap}>
        <Button
          className={classes.actionBtn}
          variant="contained"
          color="primary"
          onClick={() => saveEditObject(config.path)}
        >
          UPDATE
        </Button>
        <Button
          className={classes.actionBtn}
          variant="text"
          onClick={() => cancelEditObject()}
        >
          CANCEL
        </Button>
        <Button
          className={classes.actionBtn}
          variant="text"
          onClick={() => removeObject(config.path, obj.objectIndex)}
        >
          REMOVE
        </Button>
      </div>
    </Paper>
  )
}

function ItemWithChart({ classes, obj, config, editObject, chartDataSet, objectList, currencySymbol }) {
  const dataSet = clone(chartDataSet);
  dataSet.datasets[0].backgroundColor =
    dataSet.datasets[0].backgroundColor.map(color => (obj.color === color) ? color : '#fff')
  dataSet.datasets[0].hoverBackgroundColor = [...dataSet.datasets[0].backgroundColor];

  return(
    <div className={classes.listItem}>
      <div className={classes.flexCentered} style={{flex: 9}}>
        <div style={{ flex: 1 }}>
          <Chart
            data={dataSet}
            width={28}
            height={28}
            options={{
              elements: {
                arc: {
                  borderWidth: 0
                }
              },
              tooltips: {
                enabled: false
              }
            }}
            objectList={objectList} />
        </div>
        <Text style={{ flex: 4, textTransform: 'capitalize' }}>
          {
            (config.display.primaryInfoPath &&
            rPath(config.display.primaryInfoPath.split('.'), obj)) ||
            'Missing Info'
          }
        </Text>
        <Text style={{ flex: 2, textTransform: 'capitalize' }}>
          {accounting.formatMoney(obj.positiveValue, currencySymbol)}
        </Text>
        <Text style={{ flex: 2, textTransform: 'capitalize' }}>
          {accounting.formatMoney(obj.myValue, currencySymbol)}
        </Text>
      </div>
      <div style={{flex: 1}}>
        <Button
          color='primary'
          onClick={() => editObject(obj.objectIndex, obj)}
        >
          Edit
        </Button>
      </div>
    </div>
  )
}

function ItemNormal({ classes, obj, config, editObject }) {
  return(
    <div className={classes.listItem}>
      <div style={{flex: 9, textTransform: config.display.capitalize ? 'capitalize' : 'none'}}>
        <Text>
          {
            (config.display.primaryInfoPath &&
            rPath(config.display.primaryInfoPath.split('.'), obj)) ||
            'Missing Info'
          }
          {(config.display.secondaryInfoPrefix || '') +
            (config.display.secondaryInfoPath &&
            rPath(config.display.secondaryInfoPath.split('.'), obj)) ||
            'Missing Info'
          }
        </Text>
      </div>
      <div style={{flex: 1}}>
        <Button
          color='primary'
          onClick={() => editObject(obj.objectIndex, obj)}
        >
          Edit
        </Button>
      </div>
    </div>
  )
}

function ObjectList({
  classes,
  config,
  objectList,
  groupedObjectList,
  editingIndex,
  editingObject,
  editObject,
  removeObject,
  saveEditObject,
  cancelEditObject,
  updateEditObjWithObjIs,
  mainChartSum,
  chartDataSet,
  currencySymbol,
  updateValidate,
  details,
}) {
  const {
    showPieChart
  } = config;

  const renderItem = (obj) => {
    const Component = showPieChart ? ItemWithChart : ItemNormal;

    return (
      <Component
        key={obj.objectIndex}
        obj={obj}
        config={config}
        classes={classes}
        editObject={editObject}
        chartDataSet={chartDataSet}
        objectList={objectList}
        currencySymbol={currencySymbol}
        updateValidate={updateValidate}
        details={details}
      />
    );
  }

  return (
    <Paper elevation={2} className={classes.objectListContainer}>
      {/* This is where the chart goes */}
      {showPieChart && objectList.length &&
        <div className={cx(classes.flexCentered, classes.mainGraphWrap)}>
          <div style={{flex:1}}>
            <Chart
              data={chartDataSet}
              width={42}
              height={42}
              options={{
                elements: {
                  arc: {
                    borderWidth: 0
                  }
                },
                tooltips: {
                  enabled: false
                }
              }} />
          </div>
          <div style={{flex:4}}>{objectList.length} {pluralize(config.objectIs)}</div>
          <div style={{flex:2}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <span style={{fontSize: '65%'}}>Value</span>
              <span>{accounting.formatMoney(mainChartSum.positiveValue, currencySymbol)}</span>
            </div>
          </div>
          <div style={{flex:2}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <span style={{fontSize: '65%'}}>My Share</span>
              <span>{accounting.formatMoney(mainChartSum.myValue, currencySymbol)}</span>
            </div>
          </div>
          <div style={{flex:1}} />
        </div>
      }
      <div className={classes.list}>
        {Object.keys(groupedObjectList).map(groupedByValue => {
          return (
            <div key={groupedByValue} className={classes.flexColumnCentered}>
              {config.groupedBy &&
                <div className={classes.groupHeader}>
                  {groupedObjectList[groupedByValue].length > 1 &&
                    groupedByValue.toUpperCase().indexOf('CHILD') > -1 ?
                    groupedByValue.toUpperCase().replace('CHILD', 'CHILDREN') :
                    groupedByValue.toUpperCase()}
                </div>
              }
              {groupedObjectList[groupedByValue].map((obj) => {
                const { objectIndex } = obj;
                const isEditing = objectIndex === editingIndex;
                return isEditing ?
                  <EditingObject
                    key={objectIndex}
                    obj={obj}
                    config={config}
                    classes={classes}
                    currencySymbol={currencySymbol}
                    saveEditObject={saveEditObject}
                    cancelEditObject={cancelEditObject}
                    updateEditObjWithObjIs={updateEditObjWithObjIs}
                    editingObject={editingObject}
                    removeObject={removeObject}
                    updateValidate={updateValidate}
                    details={details}
                  />
                : renderItem(obj)
              })}
            </div>
          );
        })}
      </div>
    </Paper>
  )
}

export default withStyles(styles)(ObjectList)