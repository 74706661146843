import actions from '../actions';
import { ReducerBuilder } from './reducer-builder';

const defaultState = {
   clientHashId: null,
};

const reducer = new ReducerBuilder(defaultState)
  .handle(actions.app.auth.logIntoClientSuccess, (state, action) => ({
    ...state,
    clientHashId: action.payload.data.hashId,
  }))
  .handle(actions.app.data.registerClientSuccess, (state, action) => ({
    ...state,
    clientHashId: action.payload.data.hashId,
  }))
  .handle(actions.app.auth.logoutSuccess, state => ({
    ...state,
    clientHashId: null,
  }))
  .done();

export default reducer;
