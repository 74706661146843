import { clone } from 'ramda';

const partnerUpdateIncoming = details => {
  let cloneDetails = clone(details);

  if (cloneDetails.partnerName && cloneDetails.partnerName.first) {
    cloneDetails = {
      ...cloneDetails,
      extensionData: {
        ...cloneDetails.extensionData,
        partner: [
          {
            dateOfBirth: cloneDetails.partnerDateOfBirth,
            title: cloneDetails.partnerTitle,
            gender: cloneDetails.partnerGender,
            fullName:
              cloneDetails.partnerName.fullName ||
              [cloneDetails.partnerName.first, cloneDetails.partnerName.surname]
                .filter(Boolean)
                .join(' '),
            name: {
              first: cloneDetails.partnerName.first,
              surname: cloneDetails.partnerName.surname,
              middle: cloneDetails.partnerName.middle,
            },
          },
        ],
      },
    };
  }

  return cloneDetails;
};

const partnerUpdateOutgoing = details => {
  let cloneDetails = clone(details);

  if (
    cloneDetails.extensionData &&
    cloneDetails.extensionData.partner &&
    cloneDetails.extensionData.partner.length > 0
  ) {
    cloneDetails = {
      ...cloneDetails,
      partnerName: {
        first: cloneDetails.extensionData.partner[0].name
          ? cloneDetails.extensionData.partner[0].name.first
          : '',
        middle: cloneDetails.extensionData.partner[0].name
          ? cloneDetails.extensionData.partner[0].name.middle
          : '',
        surname: cloneDetails.extensionData.partner[0].name
          ? cloneDetails.extensionData.partner[0].name.surname
          : '',
        fullName: cloneDetails.extensionData.partner[0].fullName,
      },
      partnerGender: cloneDetails.extensionData.partner[0].gender,
      partnerTitle: cloneDetails.extensionData.partner[0].title,
      partnerDateOfBirth: cloneDetails.extensionData.partner[0].dateOfBirth,
    };
  }
  return cloneDetails;
};

export { partnerUpdateIncoming, partnerUpdateOutgoing };
