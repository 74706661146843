import { path as rPath, groupBy } from 'ramda';

const randomColor = require('randomcolor');

const colorArray = [];

// export const getActiveTheme = (state) => state.themes.activeTheme;
export const getDetails = state => state.details.details;
export const getNewObjects = state => state.details.newObjects;
export const getEditingIndex = (state, objectIs) => state.details.editingIndex[objectIs];
export const getEditingObject = (state, objectIs) => state.details.editingObject[objectIs] || {};

export const getObjectList = (state, config) => {
  return (rPath(config.path.split('.'), state.details.details) || []).map((obj, index) => {
    const graphValues = {};
    if (config.showPieChart) {
      graphValues.positiveValue = config.chartConfig.positiveValue
        ? rPath(config.chartConfig.positiveValue.path.split('.'), obj)
        : 0;

      graphValues.negativeValue = config.chartConfig.negativeValue
        ? rPath(config.chartConfig.negativeValue.path.split('.'), obj)
        : 0;

      graphValues.yourShare = config.chartConfig.yourShare
        ? rPath(config.chartConfig.yourShare.path.split('.'), obj)
        : 0;

      switch (obj.ownership) {
        case 'you own':
        case 'you owe':
          graphValues.myValue = graphValues.positiveValue;
          break;

        case 'jointly owned with partner':
        case 'jointly owing with partner':
        case 'tenants in common':
          if (obj.yourShare) {
            graphValues.myValue = (Number(graphValues.positiveValue) / 100) * obj.yourShare;
          } else {
            graphValues.myValue = graphValues.positiveValue / 2;
          }

          break;

        case 'jointly owned':
          graphValues.myValue = graphValues.positiveValue / 2;
          break;

        case 'partner owns':
        case 'partner owes':
        default:
          graphValues.myValue = 0;
      }
    }

    let color = colorArray[obj.objectIndex];

    if (!color) {
      color = randomColor();
      colorArray[obj.objectIndex] = color;
    }

    return {
      ...obj,
      ...graphValues,
      objectIndex: index,
      color,
    };
  });
};
export const getGroupedObjectList = (state, config) =>
  groupBy(obj => obj[config.groupedBy])(getObjectList(state, config));
export const getMainChartSum = (state, config) => {
  if (!config.showPieChart) {
    return null;
  }
  return getObjectList(state, config).reduce(
    (acc, obj) => {
      return {
        positiveValue: acc.positiveValue + (Number(obj.positiveValue) || 0),
        negativeValue: acc.negativeValue + (Number(obj.negativeValue) || 0),
        myValue: acc.myValue + (Number(obj.myValue) || 0),
        yourShare: acc.yourShare + (Number(obj.yourShare) || 0),
      };
    },
    {
      positiveValue: 0,
      negativeValue: 0,
      myValue: 0,
      yourShare: 0,
    },
  );
};
export const getChartDataSet = (state, config) => {
  if (!config.showPieChart) {
    return null;
  }

  const objList = getObjectList(state, config);
  const data = objList.map(obj => Number(obj.positiveValue) || 0);
  const colors = objList.map(obj => obj.color);

  return {
    datasets: [
      {
        data,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
      },
    ],
  };
};

export const getDetailsAreSubmitted = state =>
  rPath(['details', 'details', 'extensionData', 'factFindMilestone', 'submitted'], state);
