import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { connectRouter } from 'connected-react-router'
import storage from 'redux-persist/lib/storage';
import auth from './auth';
import appState from './appState';
import details from './details';
import themes from './themes';
import temporary from './temporary';

const persistConfig = {
  key: 'root1.2.4',
  storage,
  blacklist: ['temporary', 'router', 'themes'],
  // transforms: [ThemesTransform]
};

export default (history) => persistReducer(
  persistConfig,
  combineReducers({
    router: connectRouter(history),
    auth,
    appState,
    themes,
    temporary,
    details
  })
);