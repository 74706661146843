import React from 'react';
import { path as rPath } from 'ramda';
import includes from 'ramda/src/includes';
import contains from 'ramda/src/contains';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography as Text } from '@material-ui/core';
import * as cx from 'classnames';

import {
  AddressInput,
  SingleTextInput,
  MultipleTextInput,
  DatePicker,
  BirthDatePicker,
  PickOne,
  Switch,
  SendInfo,
} from './index';

const KindToComponentMap = {
  addressInput: AddressInput,
  singleTextInput: SingleTextInput,
  multipleTextInput: MultipleTextInput,
  datePicker: DatePicker,
  birthDatePicker: BirthDatePicker,
  pickOne: PickOne,
  switch: Switch,
  sendInfo: SendInfo,
};

const styles = theme => ({
  root: {
    minHeight: '5vh',
    marginBottom: '2vh',
    backgroundColor: 'transparent',
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flex: { display: 'flex' },
  header: { alignItems: 'center' },
  headerText: { marginLeft: '1vw' },
});

function QuestionWrap({
  inline,
  classes,
  title,
  caption,
  kind,
  props,
  required = false,
  requiredDependsOnPath = false,
  path,
  details,
  updateDetail,
  currencySymbol,
  updateValidate,
  updateRequiredFields,
  validated,
  errorMessage,
  dynamic,
  dynamicTrigger,
  updateTempRequiredFields,
}) {
  let pathToUse = path;
  if (Array.isArray(path)) {
    [pathToUse] = path;
  }

  const value = path ? rPath(pathToUse.split('.'), details) : undefined;
  const Component = kind ? KindToComponentMap[kind] : null;
  const onChange = path
    ? newVal => {

        if (path) {
          updateDetail(path, newVal);

          // TODO: clean this up at some point
          if (contains(path, ['telephone', 'email', 'mobile', 'preferredContactMethod'])) {
            updateValidate(path, newVal, details);
          }

          if (required) {
            updateRequiredFields(path, newVal, required);
          }

          //if (path === 'maripartalStatus' || path === 'gender') {
          //   updateTempRequiredFields(path, newVal, details);
          // }
        }
      }
    : undefined;

  const onBirthDateInputChange = newVal => {
    updateTempRequiredFields(path, newVal); 
    updateDetail(path, newVal);
  };

  const validatePreferredContactMethod = () => {
    const config = {
      phone: 'telephone',
      email: 'email',
      mobile: 'mobile',
    };

    return (
      ['telephone', 'email', 'mobile'].includes(path) &&
      config[details.preferredContactMethod] === path &&
      !validated &&
      true
    );
  };

  // We're checking to see if we're dealing with a dynamic field here (set inside the config)
  // If we are then get the Trigger show the field
  const shouldShowQuestion = dynamic
    ? includes(details[dynamicTrigger.path], dynamicTrigger.pathValue)
    : true;

  return (
    shouldShowQuestion && (
      <Paper
        elevation={0}
        className={cx(classes.root, {
          [classes.inline]: inline,
        })}
      >
        {title && (
          <div className={cx(classes.flex, classes.header, 'noselect')}>
            <Text color="textPrimary">{title}</Text>
            {(requiredDependsOnPath
              ? details[requiredDependsOnPath] === title.toLowerCase()
              : required) && <span style={{ color: 'red' }}>*</span>}
          </div>
        )}
        {kind ? (
          <Component
            {...props}
            onChange={onChange}
            value={value}
            details={details}
            updateDetail={updateDetail}
            updateValidate={updateValidate}
            updateRequiredFields={updateRequiredFields}
            currencySymbol={currencySymbol}
            onInputChange={onBirthDateInputChange}
            preventError={validatePreferredContactMethod()}
          />
        ) : (
          <div>Actual content goes here</div>
        )}
        {caption && (
          <div>
            <Text variant="caption">{caption}</Text>
          </div>
        )}
        {validatePreferredContactMethod() && (
          <div>
            <Text variant="caption" style={{ color: 'red' }}>
              {errorMessage || 'This field is required'}
            </Text>
          </div>
        )}
      </Paper>
    )
  );
}
export default withStyles(styles)(QuestionWrap);
